import React, { Component } from 'react';
import './no-photos.scss';

export default class NoPhotos extends Component {

  render() {
    return (
      <div className="no-photos">
        <h1>No Photos</h1>
      </div>
    );
  }
}
