import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SocialIcon from './social-icon';
import './social-icons.scss';

export default class SocialIcons extends Component {

  static propTypes = {
    icons: PropTypes.arrayOf(PropTypes.object),
  };

  render() {
    const { icons } = this.props;
    return (
      <div className="social-icons">
        <div className="social-icons-container">
          {icons.map(icon => <SocialIcon type={icon.type} handle={icon.handle} key={icon.type} />)}
        </div>
      </div>
    );
  }
}
