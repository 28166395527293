import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './instagram-feed-disable.scss';

export default class InstagramFeedDisable extends Component {

  static propTypes = {
    username: PropTypes.string.isRequired,
  }

  getUserURL() {
    return `https://www.instagram.com/${this.props.username}/`;
  }

  render() {
    return (
      <div className="instagram-feed-disable">
        <h1>Well, that was unexpected&hellip;</h1>
        <p>
          This user does not have an active account with Link in Profile.
          Leave a comment letting them know.
        </p>
        <a href={this.getUserURL()}>@{this.props.username}</a>
      </div>
    );
  }
}
