import { withProps } from 'recompose';
import { get } from 'lodash';

// Need to make sure we return a new array here hence the use of slice()
// Show pinned photos first, then all others in date order
export function sortPhotos(photos, params) {
  const { field, direction, options } = params;
  return photos.slice().sort((a, b) => {
    if (options.excludePinnedPhotoFromSort) {
      if ((a.lip_photo || {}).view_status === 'pinned') return -1;
      if ((b.lip_photo || {}).view_status === 'pinned') return 1;
    }
    if (direction === 'desc') {
      return get(b, field) - get(a, field);
    }
    return get(a, field) - get(b, field);
  });
}

const withSortPhotos = withProps(() => ({ sortPhotos }));

export default withSortPhotos;
