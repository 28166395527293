import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import classNames from 'classnames';
import { IoMdClose, IoIosMore } from 'react-icons/io';
import ProfileImage from '../ui/profile-image';
import ProfileDetail from './profile-detail';
import './profile.scss';

export default class Profile extends Component {
  static propTypes = {
    source: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      image: null,
      username: null,
      name: null,
      tagline: null,
      isLoaded: false,
      isShowingDetail: false,
      lastScrollTop: 0,
      navbarHeight: 91,
      showHeader: true,
    };
  }

  componentWillMount() {
    if (this.props.source) {
      $.getJSON(this.props.source).then(result => {
        this.setState({
          image: result.image,
          isLoaded: true,
          name: result.name,
          tagline: result.tagline,
          username: result.username,
          website: result.website,
        });
      });
    }
  }

  hasScrolled(scrollTop) {
    const delta = 5;

    // Make sure they scroll more than delta
    if (Math.abs(this.state.lastScrollTop - scrollTop) <= delta) return;

    if (scrollTop > this.state.lastScrollTop && scrollTop > this.state.navbarHeight) {
      // if (scrollTop > this.state.lastScrollTop) {
      // Scroll Down
      this.setState({ showHeader: false });
    } else {
      // Scroll Up
      this.setState({ showHeader: true });
    }
    this.setState({ lastScrollTop: scrollTop });
  }

  handleScroll = event => {
    // http://stackoverflow.com/questions/28633221/document-body-scrolltop-firefox-returns-0-only-js
    // Chrome - event.target.body.scrollTop
    // FF - window.pageYOffset
    // There's something wrong here. `event.target.body` seems to only be accessible sometimes.
    const scrollTop =
      window.pageYOffset ||
      window.document.documentElement.scrollTop ||
      (event.target.body || {}).scrollTop ||
      0;
    this.hasScrolled(scrollTop);
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  getInstagramAccountUrl() {
    return `https://www.instagram.com/${this.state.username}/`;
  }

  toggleDetail = () => {
    this.setState({ isShowingDetail: !this.state.isShowingDetail });
  };

  render() {
    const { isShowingDetail, isLoaded, image, website, tagline } = this.state;
    const classes = classNames({
      profile: true,
      'is-loading': !isLoaded,
      'is-showing-detail': isShowingDetail,
      'is-hidden': !this.state.showHeader,
    });
    return (
      <header className={classes}>
        <ProfileImage
          src={image}
          link={this.getInstagramAccountUrl()}
          size={isShowingDetail ? 115 : 55}
          classNames={classNames('-is-in-header', { '-is-showing-detail': isShowingDetail })}
        />
        <div className="details">
          <h1 className="name">{this.state.name}</h1>
          <a className="username" href={this.getInstagramAccountUrl()}>
            @{this.state.username}
          </a>
          {isShowingDetail && <ProfileDetail tagline={tagline} website={website} />}
        </div>
        <div className="toggle-icon" onClick={this.toggleDetail}>
          {isShowingDetail ? <IoMdClose size={47} /> : <IoIosMore size={36} />}
        </div>
      </header>
    );
  }
}
