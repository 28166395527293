import { isNull } from 'lodash';
import { withProps } from 'recompose';
import { getJSON } from '../../javascripts/lib/request';

const withLoadPhotos = withProps(
  ({ loadPhotosUrl, setInstagramUnavailable, setPhotos, setRequestManager }) => ({
    async loadPhotos() {
      try {
        setRequestManager(state => ({ ...state, isLoadingPhotos: true }));
        const data = await getJSON(loadPhotosUrl());
        if (!data.success) {
          setInstagramUnavailable(true);
          return null;
        }
        setPhotos(photos => photos.concat(data.photos));
        setRequestManager(state => {
          const newInstagramRequestCount = state.instagramRequestCount + 1;
          return {
            ...state,
            autoloadComplete: newInstagramRequestCount === state.maxRequestCount,
            instagramRequestCount: newInstagramRequestCount,
            isLoadingPhotos: false,
            morePhotosToLoad: !isNull(data.next_max_id),
            nextMaxId: data.next_max_id,
            photoBatch: data.photos,
          };
        });
      } catch (error) {
        setRequestManager(state => ({ ...state, isLoadingPhotos: false }));
      }
      return null;
    },
  }),
);

export default withLoadPhotos;
