import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SocialIcons from './social-icons';
import './profile-detail.scss';

const TEMP_ICONS = [
  { type: 'instagram', handle: 'timfletcher' },
  { type: 'facebook', handle: 'timfletcher' },
  { type: 'twitter', handle: 'timfletcher' },
  { type: 'youtube', handle: 'timfletcher' },
  { type: 'pintrest', handle: 'timfletcher' },
];

export default class ProfileDetail extends Component {

  static propTypes = {
    tagline: PropTypes.string,
    website: PropTypes.string,
  };

  getUrl() {
    const { website } = this.props;
    if (/^https?:\/\/+/.test(website)) return website;
    return `http://${website}`;  // We have to choose of of http or https
  }

  getUrlDisplay() {
    const { website } = this.props;
    return website ? website.replace(/^https?:\/\/+/, '') : null;
  }

  render() {
    const { tagline, website} = this.props;
    return (
      <div className="profile-detail">
        {tagline && <div className="tagline">{tagline}</div>}
        {website && <a className="website" href={this.getUrl()}>{this.getUrlDisplay()}</a>}
        {/* <SocialIcons icons={TEMP_ICONS} /> */}
      </div>
    );
  }
}
