import { withProps } from 'recompose';

const withLoadPhotosUrl = withProps(({ requestManager }) => ({
  loadPhotosUrl() {
    // The photo loading URL is different depending on whether we're on the
    // dashboard or the landing page.
    const url = GLOBALS.isLanding
      ? PATHS.apiV1UserProfilePath
      : PATHS.apiV1PhotosPath;
    if (requestManager.nextMaxId) {
      return url.concat(`?next_max_id=${requestManager.nextMaxId}`);
    }
    return url;
  },
}));

export default withLoadPhotosUrl;
