import React from 'react';
import PropTypes from 'prop-types';
import { IoIosPin } from 'react-icons/io';
import './pinned-indicator.scss';

const PinnedIndicator = ({ isPinned }) => {
  if (isPinned) {
    return (
      <div className="pinned-indicator">
        <IoIosPin size={13} />
        <span>Pinned Post</span>
      </div>
    );
  }
  return null;
};

PinnedIndicator.propTypes = {
  isPinned: PropTypes.bool.isRequired,
};

export default PinnedIndicator;
