import { isEqual, pick } from 'lodash';
import { lifecycle } from 'recompose';

export default lifecycle({
  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { requestManager, hasDefaultLink, photos } = nextProps;
    const { instagramRequestCount, morePhotosToLoad } = requestManager;

    // Don't auto-iterate if there's a default link - we'll already be showing 33 photos
    if (hasDefaultLink) return;

    // Don't attempt to load further photos if there are none to load
    if (!morePhotosToLoad) return;

    // Only auto-iterate instagram if the appropriate data has changed
    // i.e. there's been a request for IG data since the last render.
    const keysToCompare = ['instagramRequestCount', 'morePhotosToLoad', 'nextMaxId', 'maxRequestCount'];
    const obj1 = pick(requestManager, keysToCompare);
    const obj2 = pick(this.props.requestManager, keysToCompare);
    if (isEqual(obj1, obj2)) return;

    const isBelowAutoloadMaxRequestCount = instagramRequestCount < requestManager.maxRequestCount;

    // The minimum number of photos to load.
    // This must be enough to vertically scroll & trigger the lazy loading.
    const hasLessThanMinimumPhotos = photos.length < requestManager.minimumRequiredPhotos;

    // Load photos if we have less than the minimum we want to display AND we've not exceeded the
    // limit of requests we want to make to find those photos.
    if (hasLessThanMinimumPhotos || isBelowAutoloadMaxRequestCount) {
      await nextProps.loadPhotos();
    }
  },
});
