import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './social-icon.scss';

export default class SocialIcon extends Component {

  static propTypes = {
    handle: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  };

  getIcon() {
    switch (this.type) {
      case 'facebook':

        break;

      default:
        break;
    }

  }

  render() {
    const { handle, type } = this.props;
    return (
      <div className="social-icon">{this.getIcon()}</div>
    );
  }
}
