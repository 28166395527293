// This file is an entry point for webpack.
// It loads all the JS required for the landing pages e.g. /timothyfletcher

// async / await
import 'core-js/stable';
import 'regenerator-runtime/runtime';

// fetch API
import 'whatwg-fetch';

// Styles
import '../src/stylesheets/landing';

// Page View Tracking
import recordPageView from '../src/javascripts/landing/record-page-view';

// Error tracking
window.Sentry = require('../src/javascripts/shared/sentry').default;

// This code is from the react-rails gem for mounting components
window.ReactRailsUJS = require('../src/javascripts/vendor/react-rails-ujs').default;

// Any components mounted (at the root node) by ReactRailsUJS
window.Profile = require('../src/components/landing/profile');
window.InstagramFeed = require('../src/components/landing/instagram-feed');

recordPageView();
