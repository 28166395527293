import React from 'react';
import PropTypes from 'prop-types';
import { compose, withProps } from 'recompose';

import classNames from 'classnames';
import { GoListUnordered } from 'react-icons/go';
import { IoMdGrid } from 'react-icons/io';
import './view-selector.scss';

const iconMap = { list: GoListUnordered, grid: IoMdGrid };

const ViewSelector = ({ brandColor: color, hint, onClick, selectedView, views }) => (
  <div className="view-selector">
    {views.map(view => (
      <button
        key={view}
        onClick={onClick.bind(this, view)}
        className={classNames(`selector ${view}`, { '-is-selected': selectedView === view })}
      >
        {React.createElement(iconMap[view], { size: 35, color })}
      </button>
    ))}
    <span className="hint">{hint}</span>
  </div>
);

const props = withProps(() => ({
  hint: 'Tap any photo to visit the link',
}));

ViewSelector.propTypes = {
  hint: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  selectedView: PropTypes.string.isRequired,
  views: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default compose(props)(ViewSelector);
