import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import { IoMdHeart, IoMdArrowDropright } from 'react-icons/io';
import { MdChatBubble } from 'react-icons/md';
import PinnedIndicator from './pinned-indicator';
import InstagramLogo from './instagram-logo';
import truncateString from '../../javascripts/lib/truncate_string';
import './instagram-media-item-meta.scss';

moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s',
    s: 's',
    m: '1m',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    M: '4w',
    MM: (number) => `${Math.round(number * 4)}w`,
    y: '52w',
    yy: (number) => `${Math.round(number * 52)}w`,
  },
});

function getHost(url) {
  const parser = window.document.createElement('a');
  parser.href = url;
  return parser.host;
}

export default class InstagramMediaItemMeta extends Component {
  static propTypes = {
    className: PropTypes.string,
    commentCount: PropTypes.number,
    createdAt: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    description: PropTypes.string,
    handleClick: PropTypes.func,
    instagramLink: PropTypes.string.isRequired,
    isPinned: PropTypes.bool.isRequired,
    likeCount: PropTypes.number,
    showDescription: PropTypes.bool.isRequired,
    title: PropTypes.string,
    url: PropTypes.string,
    target: PropTypes.string,
    rel: PropTypes.string,
  };

  static defaultProps = {
    showDescription: true,
  };

  classes() {
    const { className, isPinned, title } = this.props;
    return classNames('instagram-media-item-meta', className, {
      '-is-pinned': isPinned,
      '-post-has-no-title': !title,
    });
  }

  render() {
    const {
      commentCount,
      createdAt,
      description,
      handleClick,
      instagramLink,
      likeCount,
      isPinned,
      title,
      url,
      target,
      rel,
    } = this.props;
    return (
      <div className={this.classes()}>
        {this.props.showDescription && (
          <div className="lip-meta" onClick={handleClick}>
            <PinnedIndicator isPinned={isPinned} />
            {title && <h2>{title}</h2>}
            {description && (
              <p className="description">{truncateString(description, 280)}</p>
            )}
            <a className="link" href={url} target={target} rel={rel}>
              <span className="url">{getHost(url)}</span>
              <span className="link-icon">
                <IoMdArrowDropright size={20} />
              </span>
            </a>
          </div>
        )}
        <a
          className="instagram-meta"
          href={instagramLink}
          target={target}
          rel={rel}
        >
          <span className="instagram-logo">
            <InstagramLogo />
            <span className="date">
              {moment.unix(parseInt(createdAt, 10)).fromNow()}
            </span>
          </span>
          <div className="counts">
            {typeof likeCount === 'number' && (
              <span className="likes">
                <IoMdHeart size={24} />
                <span className="count">{likeCount}</span>
              </span>
            )}
            {typeof commentCount === 'number' && (
              <span className="comments">
                <MdChatBubble size={24} />
                <span className="count">{commentCount}</span>
              </span>
            )}
          </div>
        </a>
      </div>
    );
  }
}
