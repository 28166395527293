import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './spinner.scss';

const Spinner = ({ brandColor: backgroundColor, className, ...props }) => (
  <div className={classNames('sk-cube-grid', className)}>
    <div className="sk-cube sk-cube1" style={{ backgroundColor }} />
    <div className="sk-cube sk-cube2" style={{ backgroundColor }} />
    <div className="sk-cube sk-cube3" style={{ backgroundColor }} />
    <div className="sk-cube sk-cube4" style={{ backgroundColor }} />
    <div className="sk-cube sk-cube5" style={{ backgroundColor }} />
    <div className="sk-cube sk-cube6" style={{ backgroundColor }} />
    <div className="sk-cube sk-cube7" style={{ backgroundColor }} />
    <div className="sk-cube sk-cube8" style={{ backgroundColor }} />
    <div className="sk-cube sk-cube9" style={{ backgroundColor }} />
  </div>
);

export default Spinner;
