import React, { Component } from 'react';
import './instagram-feed-error.scss';

export default class InstagramFeedError extends Component {
  render() {
    return (
      <div className="instagram-feed-error">
        <h1>Uh oh!</h1>
        <p>
          It looks like we couldn't load these photos from Instagram.<br />
          Please refresh to try again.
        </p>
      </div>
    );
  }
}
