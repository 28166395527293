import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import DefaultAvatar from '../../images/default-avatar.svg';
import './profile-image.scss';

export default class ProfileImage extends Component {
  static propTypes = {
    src: PropTypes.string,
    link: PropTypes.string,
    classNames: PropTypes.string,
    size: PropTypes.number,
    onClick: PropTypes.func,
  };

  render() {
    return (
      <a
        href={this.props.link}
        className={classNames('profile-image', this.props.classNames, {
          '-has-link': this.props.onClick,
        })}
        onClick={this.props.onClick}
      >
        <span className="loading-indicator" />
        <div
          style={{
            height: this.props.size,
            width: this.props.size,
            backgroundImage: `url(${this.props.src || DefaultAvatar})`,
            backgroundSize: 'cover',
            backgroundColor: '#ddd',
            borderRadius: '50%',
          }}
        />
      </a>
    );
  }
}
