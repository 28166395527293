// Dependencies
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

// Components
import HandTapIcon from '../ui/icons/hand-tap';
import InstagramMediaItemMeta from './instagram-media-item-meta';

// Misc
import addProtocolToURL from '../../javascripts/lib/add_protocol_to_url';
import { GRID, LIST } from '../../javascripts/lib/constants';

// Styles
import './instagram-media-item.scss';

export default class InstagramMediaItem extends Component {
  static propTypes = {
    photo: PropTypes.object.isRequired,
    selectedView: PropTypes.string.isRequired,
    views: PropTypes.arrayOf(PropTypes.string).isRequired,
    index: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    const { index, selectedView } = props;
    this.state = {
      showClickHint:
        (selectedView === LIST && index === 0) ||
        (selectedView === GRID && index === 1),
    };
  }

  url() {
    // We have three different sources that the URL could come from. The priority is:
    //   LIP photo (i.e. they've explicity added a link)
    //   Instagram photo caption
    //   A default link from their settings
    //
    // Note that sometimes when the URL comes from the instagram caption there may not
    // be a protocol so we need to add it.
    const { instagram_photo, lip_photo } = this.props.photo;
    const url =
      (lip_photo || {}).url ||
      instagram_photo.description_url ||
      instagram_photo.default_link.url;
    return addProtocolToURL(url);
  }

  title() {
    const { instagram_photo, lip_photo } = this.props.photo;
    return (
      (lip_photo || {}).title ||
      (instagram_photo.default_link || {}).title ||
      ''
    );
  }

  description() {
    const { instagram_photo, lip_photo } = this.props.photo;
    return (
      (lip_photo || {}).description ||
      (instagram_photo.default_link || {}).description ||
      ''
    );
  }

  sendGaEventBeacon = async () => {
    try {
      const { gtag } = window;
      const { photo } = this.props;
      const photoId = photo.instagram_photo.id;
      const userId = GLOBALS.userId;

      gtag('event', 'click', {
        event_category: userId,
        event_label: photoId,
      });
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    }
  };

  sendStatsBeacon = async () => {
    try {
      const { selectedView, photo } = this.props;
      const instagram_photo_id = photo.instagram_photo.id;
      const instagram_permalink = photo.instagram_photo.link;
      const url = this.url();
      const user_id = GLOBALS.userId;
      const view_type = selectedView;

      const formData = new FormData();

      formData.append('[stat]instagram_photo_id', instagram_photo_id);
      formData.append('[stat]instagram_permalink', instagram_permalink);
      formData.append('[stat]url', url);
      formData.append('[stat]user_id', user_id);
      formData.append('[stat]view_type', view_type);

      navigator.sendBeacon(PATHS.apiV1RawClickStatsPath, formData);
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    }
  };

  handleClick = async () => {
    this.sendGaEventBeacon();
    this.sendStatsBeacon();
  };

  render() {
    const {
      selectedView,
      photo: { instagram_photo, lip_photo },
    } = this.props;
    const { showClickHint } = this.state;
    const { created_time, comments, link, likes } = instagram_photo;
    const url = this.url();
    const mediaUrl =
      instagram_photo.media_type === 'VIDEO'
        ? instagram_photo.thumbnail_url
        : instagram_photo.images.standard_resolution.url;
    const isPinned = (lip_photo || {}).view_status === 'pinned';
    const className = classNames(
      'instagram-media-item',
      `-is-${selectedView}-view`,
      {
        '-is-pinned': isPinned,
      },
    );

    return (
      <div className={className}>
        <div className="container">
          <a
            className="image-link"
            href={url}
            onClick={this.handleClick}
            target="LIP_LINK_NEW_WINDOW"
            rel="noopener nofollow"
          >
            {showClickHint && <HandTapIcon selectedView={selectedView} />}
            <div
              className="image"
              role="presentation"
              style={{ backgroundImage: `url('${mediaUrl}')` }}
            />
          </a>
          {selectedView === 'list' && (
            <InstagramMediaItemMeta
              description={this.description()}
              commentCount={comments ? comments.count : null}
              createdAt={created_time}
              handleClick={this.handleClick}
              instagramLink={link}
              isPinned={isPinned}
              likeCount={likes ? likes.count : null}
              title={this.title()}
              url={url}
              target="LIP_LINK_NEW_WINDOW"
              rel="noopener nofollow"
            />
          )}
        </div>
      </div>
    );
  }
}
