// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { throttle } from 'lodash';
import { compose, lifecycle, withHandlers, withState } from 'recompose';

// HOCs
import withAutoLoad from '../hocs/with-autoload';
import withLoadPhotos from '../hocs/with-load-photos';
import withLoadPhotosUrl from '../hocs/with-load-photos-url';
import withSortPhotos from '../hocs/with-sort-photos';

// Components
import InstagramFeedDisable from './instagram-feed-disable';
import InstagramFeedError from './instagram-feed-error';
import InstagramMediaItem from './instagram-media-item';
import NoPhotos from './no-photos';
import ViewSelector from './view-selector';
import Spinner from '../ui/spinner';

// Misc
import { LIST, GRID, VIEW_TYPES } from '../../javascripts/lib/constants';

// Styles
import './instagram-feed.scss';

class InstagramFeed extends Component {
  static propTypes = {
    handleScroll: PropTypes.func.isRequired,
    handleSelectedView: PropTypes.func.isRequired,
    hasDefaultLink: PropTypes.bool.isRequired,
    hasPublicPageAccess: PropTypes.bool.isRequired,
    instagramUnavailable: PropTypes.bool.isRequired,
    loadPhotos: PropTypes.func.isRequired,
    photos: PropTypes.arrayOf(PropTypes.object),
    selectedView: PropTypes.string.isRequired,
    requestManager: PropTypes.object.isRequired,
    username: PropTypes.string.isRequired,
    brandColor: PropTypes.string.isRequired,
  };

  componentDidMount() {
    window.addEventListener('scroll', throttle(this.props.handleScroll, 500, { leading: true }));
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.props.handleScroll);
  }

  render() {
    const {
      brandColor,
      handleSelectedView,
      hasPublicPageAccess,
      instagramUnavailable,
      photos,
      requestManager,
      selectedView,
    } = this.props;

    const className = classNames('instagram-feed-container', {
      '-is-loading': requestManager.isLoadingPhotos,
      '-is-list-view': selectedView === LIST,
      '-is-grid-view': selectedView === GRID,
    });

    if (!hasPublicPageAccess) {
      return (
        <InstagramFeedDisable
          hasPublicPageAccess={this.props.hasPublicPageAccess}
          username={this.props.username}
        />
      );
    }
    return (
      <div className="instagram-feed">
        <ViewSelector
          brandColor={brandColor}
          onClick={handleSelectedView}
          selectedView={selectedView}
          views={VIEW_TYPES}
        />
        <section className={className}>
          {photos.map((photo, index) => (
            <InstagramMediaItem
              index={index}
              key={photo.instagram_photo.id}
              photo={photo}
              selectedView={selectedView}
              views={VIEW_TYPES}
            />
          ))}
          {!requestManager.isLoadingPhotos && photos.length === 0 && <NoPhotos />}
          {requestManager.isLoadingPhotos && !instagramUnavailable && <Spinner brandColor={brandColor} />}
        </section>
        {instagramUnavailable && <InstagramFeedError />}
      </div>
    );
  }
}

const withHandleScroll = withHandlers({
  handleScroll: ({ requestManager, setRequestManager }) => async () => {
    if (!requestManager.morePhotosToLoad) return;
    const height = document.height !== undefined ? document.height : document.body.offsetHeight;
    if (window.scrollY + 1500 > height) {
      // Updating the maximum request count will cause more photos to be loaded
      setRequestManager(requestManagerState => ({
        ...requestManagerState,
        maxRequestCount: requestManagerState.maxRequestCount + 2,
      }));
    }
  },
});

const withHandleSelectedView = withHandlers({
  handleSelectedView: ({ setSelectedView }) => viewType => setSelectedView(viewType),
});

export default compose(
  withSortPhotos,
  withState('selectedView', 'setSelectedView', props => props.defaultView || LIST),
  withState('instagramUnavailable', 'setInstagramUnavailable', false),
  withState('requestManager', 'setRequestManager', {
    autoloadComplete: false,
    instagramRequestCount: 0,
    isLoadingPhotos: true,
    // This is the default number of requests we'll perform in the hope of finding at least the
    // minimum number of photos. If we do find the minumum, great. If not, we'll stop looking after
    // `maxRequestCount` requests (i.e. 33 * 7 = 231 photos).
    // This limit exists to prevent over-zealous request iteration which can cause API limits to
    // be quickly reached unless we use really long (e.g. 15+ minute) cache times. With this limi
    // we should be able to have < 5 minute cache times. The current API limit is 200 requests/hr.
    maxRequestCount: 9,
    minimumRequiredPhotos: 20,
    morePhotosToLoad: true,
    nextMaxId: null,
    photoBatch: [],
  }),
  withState('photos', 'setPhotos', []),
  withLoadPhotosUrl,
  withLoadPhotos,
  withHandleScroll,
  withHandleSelectedView,
  withAutoLoad,
  lifecycle({
    async componentWillMount() {
      await this.props.loadPhotos();
    },
    async componentDidUpdate(prevProps) {
      const {
        loadPhotos,
        requestManager: { maxRequestCount, isLoadingPhotos },
      } = this.props;
      // If we have a change in the number of max requests, kick off loading again but ONLY
      // if we're not currently loading.
      if (maxRequestCount !== prevProps.requestManager.maxRequestCount && !isLoadingPhotos) {
        await loadPhotos();
      }
    },
  }),
)(InstagramFeed);
